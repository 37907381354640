import React from "react";
import { Grid } from "@material-ui/core";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const UserAgreement = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const location = useLocation();
  const { contact } = location.state || {}; // state içinden texts prop'unu al

  return (
    <div
      className="div1"
      style={{ color: "white", paddingTop: isMobile ? "70px" : "5px" }}
    >
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>

            <br></br>

            <u>
              <p>
                <strong>
                  <a href="/">USER AGREEMENT</a>
                </strong>
              </p>
            </u>
            <br></br>
          </center>

          <p>
            <strong>1-Parties and Subject</strong>
          </p>
          <p>
            This agreement regulates the terms and conditions between Pati
            Teknoloji Dış Ticaret A.Ş. (Pati) and the user of the Step Mate
            application, and the use of the application means that this
            agreement and its annexes, the statements and conditions to be
            disclosed in the future and the changes to be made and published in
            this agreement are accepted by the user and that the user has the
            obligation to follow such changes and notifications. If you do not
            accept the following contractual provisions, do not use the
            application.
          </p>
          <p>
            <br />
            <strong>2- Enforcement rights</strong>
          </p>
          <p>
            The application and all content belongs to Pati. It may not be
            copied, used without permission or published, even partially. The
            application user's access to the application is limited to the time
            and scope permitted by Pati company. All rights of the application
            belong to Pati company. Pati company aims to keep the application
            active without being limited to any period of time. Pati company may
            temporarily or indefinitely stop the use of the application for
            technical problems, legal reasons or other reasons, including but
            not limited to technical problems, legal reasons or other reasons,
            and may assign all rights and obligations to 3rd parties without the
            consent of the user; In this case, Pati company has no
            responsibility to the user.
          </p>
          <p>
            <br />
            <strong>3- Application Activation</strong>
          </p>
          <p>
            The user may use the application after accessing his/her personal
            social media accounts. The user accepts and declares that the e-mail
            address and phone number of the e-mail address and phone number that
            the user declares belongs to his/her own person and that he/she
            consents to all kinds of messages to be sent to this e-mail and/or
            mobile phone. The user will be able to change his/her e-mail or cell
            phone and other personal information through the application. It is
            also possible to make the change request by written notification to
            {" " + contact} e-mail address. However, Pati company has the right
            not to make the necessary changes without confirming the personal
            information of the member. The protection of the username and
            password is entirely up to the user, and if the necessary
            confirmation cannot be made, access to the account linked to the
            lost username and password may not be provided.
          </p>
          <br />
          <p>
            <strong>4- General Provisions and User Responsibilities</strong>
          </p>
          <p>
            
            The user accepts and undertakes that while using the application, he/she will use 
            it in accordance with all applicable laws, especially intellectual property law, 
            and within the framework of the rules determined / to be determined by Pati company. 
            All kinds of material and moral rights and copyrights of the application and its 
            content belong to Pati company. The user agrees not to violate the said rights directly 
            or indirectly, even partially, and to compensate the compensation and income losses and 
            judicial expenses, if any, that Pati company will have to pay to third parties due to the 
            reasons arising from the use of the application, without any conditions, at the time 
            of the first request.
          </p>
          <br></br>
          
          <p>
            <strong>4.1- Referral Reward Program</strong>
          </p>
          <p>
            Accounts cannot be opened with fake e-mail, phone numbers, personal information. 
            The account cannot be made available to third parties. Minors under 18 years of 
            age may use the application with accounts to be opened by their legal representatives/parents 
            if permitted by the laws of the country in which they are located, provided that all
            responsibility lies with the representative/parents themselves. The lower limit is 18
            years of age and children under this limit cannot use the application even with the 
            consent of their representatives/parents.
          </p>
          <br></br>
          <p>
            It is the user's responsibility to access the application using devices with the 
            hardware and software features required for the application to work. Pati may make 
            updates that require higher hardware and software in order to improve the application. 
            It may change the application partially or completely.
          </p>
          <br></br>
          <p>
            Pati Company is not responsible for the personal data and privacy practices, 
            content and security of the websites that are directed through the application 
            for both advertising agreements and other reasons.
          </p>
          <br></br>
          <p>
            It is not possible to return or waive any gifts, advantages, additional features, etc. 
            received from the virtual store within the application with application points (Step Mate! Points) 
            or any other payment method.
          </p>
          <br></br>
          <p>
            Pati is not responsible for the correspondence between users and its content. 
            In the event that the correspondence is contrary to the law and general rules of 
            morality, the account of the relevant user may be temporarily or permanently closed.
          </p>
          <br></br>
          <p>
            The user agrees that Pati Company has no liability for any damages caused directly or 
            indirectly by the application, the services offered or the application through the 
            application to the user by third parties.
          </p>
          <br></br>
          <p>
            <strong>4.2 Misuse of Referral Reward Program</strong>
          </p>
          <p>
            By agreeing to these terms, you acknowledge that the referral reward program is 
            intended for genuine referrals made by users with valid, non-fraudulent accounts. 
            Any attempt to exploit the system, including but not limited to creating fake or 
            disposable email accounts for the purpose of redeeming bonus points, is strictly 
            prohibited. Such actions constitute a violation of this agreement and may result in 
            the termination of your account and forfeiture of any accrued rewards. We reserve the 
            right to take appropriate action, including legal recourse if necessary, to address 
            misuse of the referral reward program.
          </p>
          <br></br>




          <p>
            <strong>5- Personal Data and User&apos;s Explicit Consent</strong>
          </p>
          <p>
            By using the Step Mate application, the user agrees to allow the use
            of his/her personal information for the purposes set forth herein,
            including but not limited to, providing general and personalized
            campaigns, gifts, advantages, etc. in case of registration and/or
            participation in events, solving problems that may arise due to the
            application or other reasons.
          </p>
          <p>
            The user has consented to the processing of his/her name, surname
            and the information in the sections of the personal information
            filled in the relevant forms, location and ip address on the
            internet network, related fields, etc. information, preferences,
            both for general statistical studies and to prepare general or
            special campaigns, advantages and gifts. In this context, he/she
            knows and allows that his/her digital, direct or indirect data
            (including step information) can be collected, stored as long as
            necessary within legal limits and under cyber security measures.
          </p>
          <p>
            The user has given consent for Pati to share his/her personal data
            with all kinds of service providers, suppliers, business partners,
            relevant institutions and third parties in case of legal
            requirements. These may include those located abroad. The sharing
            here is limited in direct proportion to the purpose and is intended
            to increase the quality of the service provided and to ensure direct
            service fulfillment. The user has also consented to the sharing of
            the data that must be shared in accordance with legal regulations
            with the relevant institutions.
          </p>
          <br></br>
          <ul>
            <li>
              <strong>Your Rights under Article 11 of Law No. 6698</strong>
            </li>
          </ul>
          <p>
            Pursuant to Article 11 of Law No. 6698; Everyone, by applying to the
            data controller;
            <ul>
              <li>a) To learn whether personal data is processed, </li>
              <li>
                b) To request information if personal data has been processed,{" "}
              </li>
              <li>
                c) To learn the purpose of processing personal data and whether
                it is used in accordance with its purpose,{" "}
              </li>
              <li>
                d) To know the third parties to whom personal data is
                transferred domestically or abroad,{" "}
              </li>
              <li>
                e) Requesting correction of personal data in case of incomplete
                or incorrect processing,{" "}
              </li>
              <li>
                f) Requesting the deletion or destruction of personal data
                within the framework of the conditions stipulated in Article 7,
              </li>
              <li>
                g) Requesting notification of the transactions made in
                accordance with subparagraphs (d) and (e) to third parties to
                whom personal data are transferred,{" "}
              </li>
              <li>
                h) To object to the emergence of a result to the detriment of
                the person himself/herself by analyzing the processed data
                exclusively through automated systems,{" "}
              </li>
              <li>
                i) In case of damage due to unlawful processing of personal
                data, it has the right to demand compensation for the damage.
              </li>
            </ul>
          </p>
          <br></br>
          <ul>
            <li>
              <strong>Explicit Consent&nbsp;</strong>
            </li>
          </ul>
          <p>
            The user has given explicit consent to the collection and storage of
            personal data for the purposes listed above, to be shared
            domestically and internationally, to improve and personalize the
            services offered, to be used for commercial purposes and to make
            notifications via text message, mail, voice message and other means
            and to be shared with third parties.
          </p>
          <br />
          <p>
            <strong>6- Termination</strong>
          </p>
          <p>
            If the User violates the provisions of the Agreement in whole or in
            part, however, if Pati Company deems it necessary, it may
            temporarily or indefinitely stop the user from using the user's
            account without any conditions and may terminate the agreement
            unilaterally without the need for any notification.
          </p>
          <p>
            In case it is understood that the laws or the rules determined by
            the Pati company are not complied with, it may cancel all gains and
            may also claim back the past gains of the user.
          </p>
          <br />
          <p>
            <strong>7- Competent Court and Applicable Law</strong>
          </p>
          <p>
            All disputes will be resolved at the Istanbul Central Courthouse in
            accordance with the laws of the Republic of Turkey.
          </p>
          <br />
          <p>
            <strong>8- Notification addresses</strong>
          </p>
          <p>
            Notifications to the e-mail address specified in the user's account
            are deemed to have been made to the user and no further notification
            is required. The user must keep his/her e-mail address active and
            notify any changes. Otherwise, even if the notification to be sent
            does not arrive, it is deemed to have been made.
          </p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default UserAgreement;
