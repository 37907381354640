import React from "react";
import { Grid } from "@material-ui/core";
import logo from "../../assets/images/logo.png";

import ig from "../../assets/images/ig.png";
import fb from "../../assets/images/fb.png";
import tiktok from "../../assets/images/tiktok.png";
import email from "../../assets/images/email.png";

import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import "../../css/footer.css";
import { useMediaQuery } from "react-responsive";
import id from "../../assets/languages/web-id.json"; // Endonezyaca dil dosyası

const handleLinkClick = () => {
  window.scrollTo(0, 0); // Scrolls to the top of the page
};

const Footer = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const navigate = useNavigate();

  const handleFaqClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    if (props.texts === id) {
      navigate("/faq-ind", { state: { contact: props.texts.contact } });
    } else {
      navigate("/faq", { state: { contact: props.texts.contact } });
    }
  };

  const handlePrivacyClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    if (props.texts === id) {
      navigate("/privacy-id", { state: { contact: props.texts.contact } });
    } else {
      navigate("/privacy", { state: { contact: props.texts.contact } });
    }
  };

  const handleTermsClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    if (props.texts === id) {
      navigate("/terms-id");
    } else {
      navigate("/terms");
    }
  };

  const handleUserClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    if (props.texts === id) {
      navigate("/userAgreement-id", {
        state: { contact: props.texts.contact },
      });
    } else {
      navigate("/userAgreement", { state: { contact: props.texts.contact } });
    }
  };

  const handleCookieClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
    if (props.texts === id) {
      navigate("/cookiePolicy-id", { state: { contact: props.texts.contact } });
    } else {
      navigate("/cookiePolicy", { state: { contact: props.texts.contact } });
    }
  };

  return (
    // style={{paddingBottom:'10%', paddingTop:'5%'}
    <div className="footerDiv" style={{ paddingTop: "80px" }}>
      <Grid container spacing={0} alignItems="center">
        <Box component={Grid} xs={1} sm={1} md={2} />

        <Grid item xs={2} sm={2} md={2}>
          <div className="mainLogoDiv">
            <img
              src={logo}
              alt="logo"
              style={{ width: isMobile ? "80%" : "100%", objectFit: "cover" }}
            />
          </div>
        </Grid>

        <Box component={Grid} xs={2} sm={1} display={{ md: "none" }} />

        <Grid item xs={6} sm={3} md={3}>
          <div className="footerTextDiv">
            <div className="footerText">
              <a onClick={handleFaqClick} className="footerText">
                {props.texts.faq}
              </a>
            </div>
            <div className="footerText">
              <a onClick={handlePrivacyClick} className="footerText">
                {props.texts.privacy_policy}
              </a>
            </div>
            <div className="footerText">
              <a onClick={handleUserClick} className="footerText">
                {props.texts.user_agreement}
              </a>
            </div>
            <div className="footerText">
              <a onClick={handleTermsClick} className="footerText">
                {props.texts.terms_of_use}
              </a>
            </div>
            <div className="footerText">
              <a onClick={handleCookieClick} className="footerText">
                {props.texts.cookie_policy}
              </a>
            </div>
          </div>
        </Grid>

        <Box component={Grid} xs={1} display={{ md: "none", sm: "none" }} />

        <Box component={Grid} xs={3} display={{ md: "none", sm: "none" }} />

        <Grid item xs={6} sm={4} md={3}>
          <div className="socialMediaFooterContainer">
            <div className="socialMediaFooterDiv">
              <div className="footerTextInfo"> {props.texts.follow_us} </div>
              <div className="logoContainer">
                <div className="socialMediaDiv">
                  <a href={props.texts.instagram} target="_blank">
                    <img
                      src={ig}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
                <div className="socialMediaDiv">
                  <a href={props.texts.facebook} target="_blank">
                    <img
                      src={fb}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
                <div className="socialMediaDiv">
                  <a href={props.texts.tiktok} target="_blank">
                    <img
                      src={tiktok}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="socialMediaFooterDiv">
              <div className="footerTextInfo"> {props.texts.contact_us} </div>
              <div className="logoContainer">
                <div className="socialMediaDiv">
                  <a href={"mailto:" + props.texts.contact}>
                    <img
                      src={email}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Box component={Grid} xs={3} display={{ md: "none", sm: "none" }} />
      </Grid>
    </div>
  );
};

export default Footer;
