import React from "react";
import { Grid } from "@material-ui/core";
import "../css/terms.css";
import logo from "../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";

const TermsId = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  return (
    <div className="div1" style={{ color: "white" }}>
      <center>
        <div class="mainLogoDivHeader">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              style={{
                width: isMobile ? "50%" : "10%",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          </a>
        </div>
      </center>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={10} sm={10} md={10}>
          <br></br>
          <br></br>
          <center>
            <h1>Pati Game Studio</h1>
            <h1>StepMate!</h1>
            <br></br>
            <u>
              <p>
                <strong>
                  <span>
                    SYARAT DAN KETENTUAN PENGGUNAAN APLIKASI STEP MATE!
                  </span>
                </strong>
              </p>
            </u>
            <br></br>
          </center>

          <br />
          <p>
            <span>
              1- Aplikasi Step Mate! adalah aplikasi di mana Anda dapat
              mengumpulkan poin secara virtual (Step Mate! Points) dan
              mengumpulkannya untuk ditukar dengan hadiah atau layanan lain yang
              ditawarkan. Yang perlu Anda lakukan hanyalah berjalan. Sebagai
              imbalan atas konfirmasi langkah-langkah ini, Anda akan mendapatkan
              poin virtual (Step Mate! Points) untuk jumlah langkah di bawah
              kondisi berikut.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <br />
          <p>
            <span>
              2- Anda dapat mendapatkan (Step Mate! Points) maksimal untuk
              20.000 langkah dan minimal 10 langkah setiap hari.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <br />
          <p>
            <span>
              3- Setiap 1000 langkah bernilai 1 poin mata uang, asalkan energi
              Anda mencapai kapasitas 100%. Tambahan 10% ditambahkan untuk
              penggunaan energi bonus.
            </span>
          </p>
          <br />
          <p>
            <span>
              4-Anda dapat mengisi ulang energi Anda dengan menekan ikon "petir"
              pada aplikasi, dan dengan energi penuh, langkah-langkah Anda akan
              dihitung sebagai 10% lebih berharga.
            </span>
          </p>

          <p>
            <span>
              Warna lain dan tarif perhitungan ditunjukkan di bawah ini.
            </span>
          </p>
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <table
              style={{
                width: "0.0pt",
                borderCollapse: "collapse",
                border: "none",
                textAlign: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              <thead style={{ fontWeight: "bold" }}>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      border: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "bottom",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        Energy Indicator
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderLeft: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "bottom",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        Energy Multiplier
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderLeft: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "bottom",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        Physical Step Taken
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderLeft: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "bottom",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        (SMP) Value
                      </span>
                    </p>
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      ></span>
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        Bonus Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        1.1
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        22
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        8 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        1
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        7 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.8
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        16
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        6 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.6
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        12
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        5 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.4
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        8
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        4 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.2
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        4
                      </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        3 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.2
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        4
                      </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        2 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.2
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        4
                      </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      width: "192pt",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderLeft: "1pt solid rgb(134, 134, 134)",
                      borderImage: "initial",
                      borderTop: "none",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: " normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        1 Energy
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "65.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        0.1
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "68.25pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        20,000
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "66.75pt",
                      borderTop: "none",
                      borderLeft: "none",
                      borderBottom: "1pt solid rgb(134, 134, 134)",
                      borderRight: "1pt solid rgb(134, 134, 134)",
                      padding: "0cm 5.4pt",
                      verticalAlign: "top",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "0cm",
                        marginRight: "0cm",
                        marginBottom: ".0001pt",
                        marginLeft: "0cm",
                        lineHeight: "normal",
                        fontSize: "15px",
                        fontFamily: '"Calibri",sans-serif',
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          color: "#212529",
                        }}
                      >
                        2
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <p>
            <span>
              Langkah-langkah harus dikonversi menjadi (Step Mate! Points)
              setiap hari. Langkah-langkah tidak dapat dipindahkan ke hari
              berikutnya dan direset. Oleh karena itu, jangan lupa untuk
              mengonversi langkah-langkah Anda menjadi (Step Mate! Points)
              setiap hari. Dari batas bawah, Anda dapat mengonversi
              langkah-langkah Anda menjadi (Step Mate! Points) dalam interval
              apa pun yang Anda inginkan hingga pukul 23:59 setiap hari, dan
              Anda dapat menggunakan bonus hingga pukul 23:50.
            </span>
          </p>
          <br />
          <p>
            <span>
              6- Anda dapat menukar saldo (Step Mate! Points) Anda untuk produk
              pilihan Anda dari toko aplikasi. Rincian lain tentang produk
              tersebut dijelaskan dalam deskripsi produk tersebut. Namun, Anda
              hanya dapat membeli 1 produk yang sama kecuali dinyatakan lain.
              Anda dapat melacak pesanan Anda melalui aplikasi. Perusahaan kami
              tidak bertanggung jawab atas ketidakakuratan
              alamat-kegagalan/kerusakan/kehilangan produk dalam pengiriman dan
              pengembalian produk oleh perusahaan kurir. Namun, pengguna kami
              dapat meminta pengiriman produk yang sama untuk kedua kalinya dari
              panel "Hadiah" dalam aplikasi. Dalam hal ini, biaya pengiriman
              akan menjadi tanggungan pembeli. Dalam semua kasus ini, (Step
              Mate! Points) tidak dikembalikan.
            </span>
          </p>
          <br />
          <p>
            <span>
              7- Penting untuk memeriksa toko secara teratur untuk mendapatkan
              informasi tentang kampanye dan perubahan produk.
            </span>
          </p>
          <br />
          <p>
            <span>
              8- (Step Mate! Points) yang diperoleh akan direset pada hari ulang
              tahun keanggotaan dan tidak akan dipindahkan ke tahun keanggotaan
              berikutnya.
            </span>
          </p>
          <br />
          <p>
            <span>
              9- Bahkan jika Anda menghapus akun Anda, menginstal ulang aplikasi
              tidak akan menghapus langkah-langkah Anda yang belum dikonversi
              menjadi (Step Mate! Points).
            </span>
          </p>
          <br />
          <p>
            <span>
              10- Jika diketahui adanya kecurangan dalam penggunaan aplikasi
              dengan metode apa pun - (Step Mate! Points) yang diperoleh diambil
              kembali dan keanggotaan anggota yang bersangkutan dibatalkan.
              Keanggotaan anggota yang keanggotaannya dibatalkan atas alasan ini
              dan anggota lain yang terdeteksi terhubung dari alamat IP yang
              sama juga dapat dibatalkan. Dalam hal ini, pengguna tidak memiliki
              hak untuk mengklaim hak atau piutang apa pun terhadap Pati
              Teknoloji Dış Ticaret A.Ş.
            </span>
          </p>
          <br />
          <p>
            <span>
              11- Karena penggunaan beberapa penyedia iklan, kami
              merekomendasikan agar Anda mengonversi langkah-langkah Anda
              menjadi (Step Mate! Points) di pagi hari agar tidak terpengaruh
              secara negatif oleh kedua penyedia iklan dan alasan teknis dan
              kepadatan. Perusahaan kami tidak bertanggung jawab atas tidak
              tersedianya iklan atas alasan tersebut.
            </span>
          </p>
          <br />
          <p>
            <span>
              12- Perusahaan kami tidak bertanggung jawab atas iklan dalam
              aplikasi. Iklan disediakan oleh perusahaan penyedia iklan
            </span>
          </p>
          <br />
          <p>
            <span>
              13- Aplikasi ini memiliki sistem penghargaan atau undian yang
              sepenuhnya disediakan oleh pengembang aplikasi. Apple dan Google
              tidak terlibat dalam kontes atau undian ini dan tidak memberikan
              sponsor atau dukungan apa pun. Pengembang aplikasi sepenuhnya
              bertanggung jawab atas menyediakan hadiah dan mengorganisir
              undian. Jika Anda mengalami masalah atau masalah saat menggunakan
              aplikasi, harap hubungi pengembang aplikasi secara langsung. Apple
              dan Google tidak memberikan jaminan atau menganggap tanggung jawab
              apa pun untuk undian atau sistem penghargaan ini.
            </span>
          </p>

          <br></br>
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={1} sm={1} md={1} />
      </Grid>
    </div>
  );
};

export default TermsId;
