import React from "react";
import { Grid } from "@material-ui/core";
import phone1 from "../../../assets/images/phone1.png";
import stepLine from "../../../assets/images/stepLine.png";
import step1 from "../../../assets/images/step1.png";
import step2 from "../../../assets/images/step2.png";
import step3 from "../../../assets/images/step3.png";
import step4 from "../../../assets/images/step4.png";
import Box from "@mui/material/Box";
import "../../../css/middleBody.css";

const MiddleBody = (props) => {
  return (
    <div style={{ paddingTop: "0%" }}>
      <Grid container spacing={0} alignItems="center">
        <Box component={Grid} item xs={1} sm={1} md={2} />

        <Grid item xs={10} sm={9} md={3}>
          <div style={{ minWidth: "100px" }}>
            <div className="whiteSmallTextDiv">{props.texts.few_simple}</div>
            <div className="greenSmallTextDiv">{props.texts.steps_to_win}</div>
          </div>
        </Grid>

        <Box component={Grid} item xs={1} sm={2} md={1} />
        <Box component={Grid} item xs={12} sm={12} md={6} />
      </Grid>

      <Grid container spacing={0} alignItems="center">
        <Box component={Grid} item xs={1} sm={1} md={2} />

        <Grid item xs={10} sm={5} md={4}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <div style={{ marginTop: "0px", marginRight: "0px" }}>
                <div style={{ marginTop: "0px" }}>
                  <img src={step1} alt="main1" className="stepImage1" />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <img src={step2} alt="main1" className="stepImage2" />
                </div>
                <div style={{ marginTop: "35px" }}>
                  <img src={step3} alt="main1" className="stepImage3" />
                </div>
                <div style={{ marginTop: "55px" }}>
                  <img src={step4} alt="main1" className="stepImage4" />
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div>
                {/* <img src={stepLine} alt="main1" style={{ marginTop:'0px', width: '34px', height: '9%' }} /> */}
                <img src={stepLine} alt="main1" className="stepImageDiv" />
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div
                style={{
                  marginTop: "0px",
                  marginLeft: "-70px",
                  marginRight: "0px",
                }}
              >
                <div className="whiteStepTextDiv1">
                  {props.texts.start_walking}
                </div>
                <div className="whiteStepTextDiv2">
                  {props.texts.convert_steps_to_points}
                </div>
                <div className="whiteStepTextDiv3">
                  {props.texts.watch_videos_invite_friends}
                </div>
                <div className="whiteStepTextDiv4">
                  {props.texts.win_exciting_prizes}
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Box
          component={Grid}
          item
          xs={1}
          display={{ sm: "none", md: "none" }}
        />

        <Box
          component={Grid}
          item
          xs={1}
          display={{ md: "none", sm: "none" }}
        />

        <Grid item xs={10} sm={6} md={4}>
          <div>
            <img
              src={phone1}
              alt="main1"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Grid>

        <Box component={Grid} item xs={1} md={2} display={{ sm: "none" }} />
      </Grid>
    </div>
  );
};

export default MiddleBody;
