import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CharityShare.css'; // Include a CSS file for styling
import iosIcon from "../../assets/images/iosIcon.png";
import playIcon from "../../assets/images/playIcon.png";
import logo from "../../assets/images/logo.png";
import { useParams } from 'react-router-dom';

const CharityShare = () => {
  const { code } = useParams();
  const [charityInfo, setCharityInfo] = useState({
    charityShareTitle: '',
    charityShareMessage: '',
    charitySharePageImage: '',
    charityShareCode: '',
  });

  const [showText, setShowText] = useState(true); // State to show/hide text
  const [showLogos, setShowLogos] = useState(false); // State to show/hide logos
  const [fadeOut, setFadeOut] = useState(false); // State to trigger fade-out animation

  useEffect(() => {
    const fetchCharityShareInfo = async () => {
      try {
        const response = await axios.get(`https://apiuk.sm-app.site/products/charityShareInfo`, {
          params: { code },
        });
        setCharityInfo(response.data);
      } catch (error) {
        console.error('Error fetching charity share info:', error);
      }
    };

    if (code) {
      fetchCharityShareInfo();
    }
  }, [code]);

  const handleAcceptInvite = () => {
    // Trigger fade-out animation
    setFadeOut(true);
    setTimeout(() => {
      setShowText(false);
      setShowLogos(true);
    }, 400);
  };

  return (
    <div
      className="charity-share-container"
      style={{ backgroundImage: `url(${charityInfo.charitySharePageImage})` }}
    >
      <div className="charity-share-body">
        {showText && (
          <div className={`charity-share-content ${fadeOut ? 'fade-out' : ''}`}>
            <button className="accept-invite-button" onClick={handleAcceptInvite}>
              Accept Invite
            </button>
            <h1>{charityInfo.charityShareTitle}</h1>
            <p>{charityInfo.charityShareMessage}</p>
          </div>
        )}

        {showLogos && (
          <div className={`fade-in`}>
            <div className="inner-store-logos">
              <a href="https://apps.apple.com/id/app/stepmate/id6476861019">
                <img src={iosIcon} alt="iOS App" className="appleStoreIcon" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.pati.stepmate.app">
                <img src={playIcon} alt="Google Play App" className="playStoreIcon" />
              </a>
            </div>

            <div className="logo-container">
              <img src={logo} alt="Logo" className="logo" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CharityShare;



// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import './CharityShare.css'; // Include a CSS file for styling
// import iosIcon from "../../assets/images/iosIcon.png";
// import playIcon from "../../assets/images/playIcon.png";
// import logo from "../../assets/images/logo.png";
// import { useParams } from 'react-router-dom';

// const CharityShare = () => {
//   const { code } = useParams();
//   const [charityInfo, setCharityInfo] = useState({
//     charityShareTitle: '',
//     charityShareMessage: '',
//     charitySharePageImage: '',
//     charityShareCode: '',
//   });

//   const [showText, setShowText] = useState(true); // State to show/hide text
//   const [showLogos, setShowLogos] = useState(false); // State to show/hide logos

//   useEffect(() => {
//     const fetchCharityShareInfo = async () => {
//       try {
//         const response = await axios.get(`https://apiuk.sm-app.site/products/charityShareInfo`, {
//           params: { code },
//         });
//         setCharityInfo(response.data);
//       } catch (error) {
//         console.error('Error fetching charity share info:', error);
//       }
//     };

//     if (code) {
//       fetchCharityShareInfo();
//     }
//   }, [code]);

//   const handleAcceptInvite = () => {
//     // Hide text and show logos with fade-in effect
//     setShowText(false);
//     setShowLogos(true);
//   };

//   return (
//     <div
//       className="charity-share-container"
//       style={{ backgroundImage: `url(${charityInfo.charitySharePageImage})` }}
//     >

//       <div className="charity-share-body">
//         {showText && (
//           <div className="charity-share-content">

// <button className="accept-invite-button" onClick={handleAcceptInvite}>
//         Accept Invite
//       </button>
//             <h1>{charityInfo.charityShareTitle}</h1>
//             <p>{charityInfo.charityShareMessage}</p>
//           </div>
//         )}

//         {showLogos && (
//           <div className={`fade-in`} >

//             <div className={`inner-logos`} >
//               <a href="https://apps.apple.com/id/app/stepmate/id6476861019">
//                 <img src={iosIcon} alt="iOS App" className="appleStoreIcon" />
//               </a>
//               <a href="https://play.google.com/store/apps/details?id=com.pati.stepmate.app">
//                 <img src={playIcon} alt="Google Play App" className="playStoreIcon" />
//               </a>
//               </div>

//             <div
//           className="logo-container"
//           style={{
//             alignItems: "center",
//             alignContent: "center",
//             justifyContent: "center",
//           }}
//         >
//           <img src={logo} alt="Logo" className="logo" />
//         </div>
//           </div>

          
//         )}


//       </div>
//     </div>
//   );
// };

// export default CharityShare;











// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './CharityShare.css'; // Include a CSS file for styling
// import iosIcon from "../../assets/images/iosIcon.png";
// import playIcon from "../../assets/images/playIcon.png";
// import logo from "../../assets/images/logo.png";
// import { useParams } from 'react-router-dom';


// const CharityShare = () => {

//   const { code } = useParams();
//   const [charityInfo, setCharityInfo] = useState({
//     charityShareTitle: '',
//     charityShareMessage: '',
//     charitySharePageImage: '',
//     charityShareCode: '',
//   });

//   useEffect(() => {
//     const fetchCharityShareInfo = async () => {
//       try {
//         const response = await axios.get(`https://apiuk.sm-app.site/products/charityShareInfo`, {
//           params: { code },
//         });
//         setCharityInfo(response.data);
//       } catch (error) {
//         console.error('Error fetching charity share info:', error);
//       }
//     };

//     if (code) {
//       fetchCharityShareInfo();
//     }
//   }, [code]);

//   return (
// <div
//   className="charity-share-container"
//   style={{ backgroundImage: `url(${charityInfo.charitySharePageImage})` }}
// >

//   <div className="charity-share-body">
//       <div className="charity-share-content">
//         <h1>{charityInfo.charityShareTitle}</h1>
//         <p>{charityInfo.charityShareMessage}</p>
//       </div>


//       <div style={{ marginTop: "60px" }}>
//         <a href="https://apps.apple.com/id/app/stepmate/id6476861019">
//           <img src={iosIcon} alt="main1" className="appleStoreIcon" />
//         </a>
//         <a href="https://play.google.com/store/apps/details?id=com.pati.stepmate.app">
//           <img src={playIcon} alt="main1" className="playStoreIcon" />
//         </a>
//       </div>


//       <div
//         style={{
//           alignItems: "center",
//           alignContent: "center",
//           justifyContent: "center",
//         }}
//       >
//         <img src={logo} alt="main1" className="logo" />
//       </div>


//     </div>
// </div>

//   );
// };

// export default CharityShare;
